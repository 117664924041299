import React from "react"
import Layout from "../components/Layouts/Layout"
import SEO from "../components/SEO"
import BlogEntries from "../components/BlogEntries"

const description = "Want to learn more about email marketing, SEO, copywriting, campaign management and more? Read all about digital marketing at Creative Ilk's marketing blog."



const Blog: React.FunctionComponent = () => (
  <Layout title="Blog">
    <SEO title="Blog" description={description} />
    <BlogEntries />
  </Layout>
)

export default Blog
